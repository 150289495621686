import mock from '@/fake-db/mock.js'

// Contact
const LinksDb = {
    allLinks: [
        {
            id: 1,
            title: 'ASGS',
            link:
                'https://www.minervaschulen.ch/de-CH/Standorte/Zuerich',
            color: 'info lighten-4',
        },
        {
            id: 2,
            title: 'SMT Swissmains',
            link:
                'https://www.swissmains.com/de',
            color: 'success lighten-4',
        },
        {
            id: 3,
            title: 'Facebook Minerva',
            link:
                'https://www.facebook.com/minervaschulen',
            color: 'warning lighten-4',
        },
        {
            id: 4,
            title: 'YouTube Minerva',
            link:
                'https://www.youtube.com/channel/UCICWw4GNeCLgtQBT5a-dtgA/videos',
            color: 'purple lighten-4',
        },
        {
            id: 5,
            title: 'Instagram Minerva',
            link:
                'https://www.instagram.com/minerva_schulen',
            color: 'danger lighten-4',
        },
    ],
}

// GET: Fetch Todos
mock.onGet('/api/links').reply(() => {
    const response = LinksDb.allLinks
    return [200, response]
})
mock.onPost('/api/links/add').reply((response) => {
    const link = JSON.parse(response.data).data
    const task = LinksDb.allLinks.push({
        title: link.title,
        link: link.link,
        color: link.color.hex,
    })

    return [200, task]
})
mock.onPost('/api/links/delete').reply((response) => {
    const link = JSON.parse(response.data)
    console.log(link.id)
    LinksDb.allLinks = LinksDb.allLinks.filter((x) => x.id !== link.id)

    return [200, link]
})
