<template>
    <v-row>
        <v-col 
        cols="auto"
        class="mr-auto"
        >
            <v-card-title>{{ title }}</v-card-title>
        </v-col>
        <v-col cols="auto">
            <v-menu
                bottom
                offset-y
                left
            >
                <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip top v-if="marks">
                    <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                        color="primary"
                        fab
                        small
                        dark
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                    >
                        <v-icon>mdi-certificate</v-icon>
                    </v-btn>
                    </template>
                    <span>Noten</span>
                </v-tooltip>
                </template>
                <v-list dense>
                <v-list-item-group
                    color="primary"
                >
                    <v-list-item href="experience-marks-pma" :disabled="validateCurrent('experience-marks')">
                        <v-list-item-icon>
                            <v-icon>mdi-certificate</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Erfahrungsnoten</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item href="quarter-marks-pma" :disabled="validateCurrent('quarter-marks')">
                        <v-list-item-icon>
                            <v-icon>mdi-certificate</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Zwischenbericht</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item href="semester-marks-pma" :disabled="validateCurrent('semester-marks')">
                        <v-list-item-icon>
                            <v-icon>mdi-certificate</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Semesternoten</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item href="final-marks-pma" :disabled="validateCurrent('final-marks')">
                        <v-list-item-icon>
                            <v-icon>mdi-certificate</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Abschlussnoten</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
                </v-list>
            </v-menu>
            <v-tooltip top v-if="comments">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="success"
                        fab
                        small
                        dark
                        class="ml-2"
                        v-bind="attrs"
                        v-on="on"
                        :href="getLink('comments')"
                    >
                        <v-icon>mdi-comment</v-icon>
                    </v-btn>
                </template>
                <span>Kommentare</span>
            </v-tooltip>
            <v-tooltip top v-if="presence">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="error"
                        fab
                        small
                        dark
                        class="ml-2 mr-3"
                        v-bind="attrs"
                        v-on="on"
                        :href="getLink('presence')"
                    >
                        <v-icon>mdi-alarm</v-icon>
                    </v-btn>
                </template>
                <span>Präsenzkontrolle</span>
            </v-tooltip>
            <v-tooltip top v-if="edit_marks">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="orange"
                        fab
                        small
                        dark
                        class="ml-2 mr-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="$emit('edit-mode', true)"
                    >
                    <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>Bearbeiten</span>
            </v-tooltip>
            <v-tooltip top v-if="new_mark">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="teal"
                        fab
                        small
                        dark
                        class="mr-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="$emit('new-mark', true)"
                    >
                    <v-icon>mdi-shield-plus</v-icon>
                    </v-btn>
                </template>
                <span>Neue Note</span>
            </v-tooltip>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'IconClassBar',
    props: {
        title: {
            type: String,
            default: '',
        },
        marks: {
            type: Boolean,
            default: false,
        },
        comments: {
            type: Boolean,
            default: false,
        },
        presence: {
            type: Boolean,
            default: false,
        },
        edit_marks: {
            type: Boolean,
            default: false,
        },
        new_mark: {
            type: Boolean,
            default: false,
        },
        current: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            links: {
                comments:   'students-list-pma',
                presence:   'presence-pma'
            }
        }
    },
    methods: {
        validateCurrent(As_BarOption) {
            if(this.current === As_BarOption) return true;
            return false;
        },
        getLink(As_BarOption) {
            if(As_BarOption !== this.current) return this.links[As_BarOption];
            return null
        },
    },
}
</script>

<style>

</style>