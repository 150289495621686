import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  state: {
    loggedInUser:
      localStorage.getItem('userInfo') != null
        ? JSON.parse(localStorage.getItem('userInfo'))
        : null,
    loading: false,
    error: null,
  },
  getters: {
    loggedInUser: state => state.loggedInUser,
    loading: state => state.loading,
    error: state => state.error,
  },
  mutations: {
    setUser (state, data) {
      state.loggedInUser = data
      state.loading = false
      state.error = null
    },
    setLogout (state) {
      state.loggedInUser = null
      state.loading = false
      state.error = null
      // this.$router.go("/");
    },
    setLoading (state, data) {
      state.loading = data
      state.error = null
    },
    setError (state, data) {
      state.error = data
      state.loggedInUser = null
      state.loading = false
    },
    clearError (state) {
      state.error = null
    },
    setRoles(state, data) {
      state.loggedInUser.roles = data
    },
  },
  actions: {
    login ({ commit }, Ai_UserID) {
      commit('clearError')
      commit('setLoading', true)
      const newUser = { 
        uid: Ai_UserID,
        roles: [] 
      }
      localStorage.setItem('userInfo', JSON.stringify(newUser))
      commit('setUser', 
        { 
          uid: Ai_UserID,
          roles: [] // admin, parent, student, teacher, company 
        }
      )
    },
    setRoles({ commit }, Aa_Roles) {
      const o_UserInfo = JSON.parse(localStorage.getItem('userInfo'));
      const updatedUser = { 
        uid: o_UserInfo.uid,
        roles: Aa_Roles 
      }
      localStorage.setItem('userInfo', JSON.stringify(updatedUser))
      commit('setRoles', Aa_Roles)
    },
    signUserUp ({ commit }, data) {
      commit('setLoading', true)
      commit('clearError')
      firebase
        .auth()
        .createUserWithEmailAndPassword(data.email, data.password)
        .then(user => {
          commit('setLoading', false)

          const newUser = {
            uid: user.user.uid,
          }
          console.log(newUser)
          localStorage.setItem('userInfo', JSON.stringify(newUser))
          commit('setUser', newUser)
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          localStorage.removeItem('userInfo')
          console.log(error)
        })
    },
    signOut ({ commit }) {
      localStorage.removeItem('userInfo')
      commit('setLogout')
    },
  },
}
