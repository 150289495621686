<template>
  <router-view />
</template>

<script>
  import { isTokenNull } from './worker/worker-api'
  import qs from 'qs'
  import { mapGetters, mapActions } from 'vuex'
  export default {
    name: 'App',
    metaInfo () {
      return {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'ASGS',
        // all titles will be injected into this template
        titleTemplate:
          '%s | ASGS ',
      }
    },
    async beforeCreate () {
      const b_IsTokenNull = await isTokenNull();
      this.login(1);
      this.setRoles(['author']);
      //if(!this.isRouteFree(this.$route) && b_IsTokenNull)
        //this.$smt.redirect();
    },
    async created () {
      const mq = window.matchMedia('(prefers-color-scheme: dark)')
      mq.addEventListener('change', e => {
        this.$vuetify.theme.dark = e.matches
      })
      this.getRouterParams();
    },
    beforeMount() {
      
    },
    methods: {
      getRouterParams () {
      // console.log(this.$router.currentRoute.query);
      },
      isRouteFree(Ao_Route) {
        let s_Url = Ao_Route.path;
        const freeRoutes = [
          '/callback',
          '/app/sessions/register',
          '/app/sessions/reset-password-request',
          '/app/sessions/user/reset-password'
        ];

        if(freeRoutes.includes(Ao_Route.path)) return true;
        for (const param in Ao_Route.params) {
          s_Url = s_Url.replace('/'+Ao_Route.params[param], ''); 
        }
        if(freeRoutes.includes(s_Url)) return true;
        return false;
      },
      ...mapActions(['login', 'setRoles']),
    },
  }
</script>
