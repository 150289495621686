/*
 * ============================
 * File: main.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Tuesday, 14th April 2020 7:17:10 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins'
// import plugin
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
//import CSS styles
import 'tiptap-vuetify/dist/main.css'

import octaviaKit from '@/plugins/octavia.kit'
import smt from '@/plugins/smt'
import VueApexCharts from 'vue-apexcharts'
// mock
import './fake-db/index.js'

Vue.component('apexchart', VueApexCharts)

Vue.use(octaviaKit)
Vue.use(smt)
Vue.config.productionTip = false

// use this package's plugin
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
})


new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
