import Worker from 'worker-loader!./worker';
import * as Comlink from 'comlink';

const worker = Comlink.wrap(new Worker());

export const get = worker.get;
export const post = worker.post;
export const getToken = worker.getToken;
export const refreshToken = worker.refreshToken;
export const isTokenNull = worker.isTokenNull;
export const getUserID = worker.getUserID;