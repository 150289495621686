import qs from 'qs'
import { get } from '../worker/worker-api'

export default {
    install(Vue, options) {
        Vue.prototype.$smt = {
            log: (text) => console.info(text),
            redirect: () => {
                const queryString = {
                    client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
                    redirect_uri: process.env.VUE_APP_OAUTH_CLIENT_REDIRECT,
                    response_type: 'code',
                    scope: '',
                }
                window.location.href = `${process.env.VUE_APP_OAUTH_AUTH_SERVER}/oauth/authorize?${qs.stringify(queryString)}`
            },
            logout: () => {
                window.location.href = `${process.env.VUE_APP_OAUTH_AUTH_SERVER}/logout/${process.env.VUE_APP_OAUTH_CLIENT_ID}`
            },
            login: async () => {
                return await get(`${process.env.VUE_APP_SMT_API_URL}/user`, null);
            },
        }
    }
}